import React from "react";
import { ThemeProvider } from "styled-components";

import NavigationProvider from "frontend/src/contexts/NavigationProvider";
import SettingsProvider from "frontend/src/contexts/SettingsProvider";
import InterfaceProvider from "frontend/src/contexts/InterfaceProvider";
import DutiesProvider from "frontend/src/contexts/DutiesProvider";

import LayoutDefault from "../../components/layout/LayoutDefault";
import ProductsProvider from "../../contexts/ProductsProvider";
import HeaderSubmenuProvider from "../../contexts/HeaderSubmenuProvider";
import UtilProviders from "../util/UtilProviders";
import CartProvider from "../../contexts/CartProvider";
import CustomerProvider from "../../contexts/CustomerProvider";
import PageMissing from "../page/PageMissing";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PageError from "../page/PageError";

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({
  children,
  pageProps,
  withLayout = false,
}: LayoutWrapperProps) => {
  if (pageProps.error) return <PageError error={pageProps.error} />;

  const props = pageProps.data;
  if (!props?.layout) return <>{children}</>;

  const { settings, navigation, theme, localization } = props.layout;

  const { shopifyProducts } = props;

  const providerList = [
    [QueryClientProvider, { client: new QueryClient() }],
    [ThemeProvider, { theme: theme || {} }],
    [SettingsProvider, { settings }],
    [NavigationProvider, { navigation }],
    [InterfaceProvider],
    [CustomerProvider],
    [CartProvider, { ...localization }],
    [DutiesProvider],
    [HeaderSubmenuProvider, { submenu: "", reference: false }],
    [ProductsProvider, { shopifyProducts: shopifyProducts || [] }],
    ...[withLayout && [LayoutDefault]],
  ].filter((v) => v);

  return (
    <UtilProviders providerList={providerList}>
      {pageProps.error ? <PageMissing error={pageProps.error} /> : children}
    </UtilProviders>
  );
};

export default React.memo(LayoutWrapper);
