import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import styled, { css } from "styled-components";
import { useCart } from "../../contexts/CartProvider";
import ButtonCheckbox from "../button/ButtonCheckbox";
import InputTextArea from "../input/InputTextArea";
import TransitionAccordion from "../transition/TransitionAccordion";
import { useDuties } from "src/contexts/DutiesProvider";

const CartControls: React.FC = ({ ...props }) => {
  const { cart, cartNoteUpdate } = useCart();
  const [noteActive, setNoteActive] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [noteTextDebounced, setNoteTextDebounced] = useState("");
  const { dutiesAccepted, setDutiesAccepted, dutiesReminder, dutiesRequired } =
    useDuties();

  useEffect(() => {
    if (!noteActive && cart?.note && cart?.note != "") setNoteActive(true);
    if (cart?.note && cart.note != noteTextDebounced) setNoteText(cart.note);
  }, [cart?.note]);

  useDebounce(
    () => {
      if (!isEditing) return;
      setNoteTextDebounced(noteText);
      cartNoteUpdate(noteText);
    },
    2000,
    [noteText, isEditing],
  );

  async function toggleNote() {
    if (noteActive && noteText != "") {
      // Do nothing
    } else {
      setNoteActive(!noteActive);
    }
  }

  return (
    <Container {...props}>
      <Top>
        <ButtonCheckbox onClick={toggleNote} isActive={noteActive}>
          Add a note
        </ButtonCheckbox>

        <TransitionAccordion isOpen={noteActive || noteText != ""}>
          <TextWrap>
            <Text
              placeholder="Add Message..."
              text={noteText}
              setText={({ currentTarget }) => {
                setIsEditing(true);
                setNoteText(currentTarget.value);
              }}
            />
          </TextWrap>
        </TransitionAccordion>
      </Top>
      {dutiesRequired && (
        <div>
          <Checkbox
            onClick={() => setDutiesAccepted(!dutiesAccepted)}
            isActive={dutiesAccepted}
            $reminder={dutiesReminder}
          >
            <span>I am responsible for any import duties and taxes</span>
            {dutiesReminder && <span>Required</span>}
          </Checkbox>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: var(--spacing-24);
  padding-top: 8px;
`;

const Top = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-charcoal200);
`;

const Checkbox = styled(ButtonCheckbox)<{ $reminder }>`
  padding-top: 8px;
  padding-bottom: 8px;

  color: var(--color-foreground);
  width: 100%;

  ${(props) =>
    props.$reminder &&
    css`
      --color-foreground: var(--color-error);
    `}
`;

const Text = styled(InputTextArea)`
  margin-top: 8px;
  height: 8em;
`;

const TextWrap = styled.div`
  overflow: hidden;
  height: 100%;
`;

export default CartControls;
