import { ProductFieldsFragment } from "library/services/shopify";
import { useEffect, useState } from "react";
import { useCart } from "src/contexts/CartProvider";
import { useProducts } from "src/contexts/ProductsProvider";

export const useCartUpsellProducts = () => {
  const { upsellProductIds, countryCode } = useCart();
  const { fetchShopifyProductById } = useProducts();

  const [shopifyProducts, setShopifyProducts] = useState<
    ProductFieldsFragment[]
  >([]);

  const updateProducts = async (ids: string[]) => {
    let newProducts: ProductFieldsFragment[] = [];

    for (let i = 0; i < ids.length; i++) {
      const newProduct: ProductFieldsFragment = await fetchShopifyProductById(
        ids[i].replace("gid://shopify/Product/", ""),
      );
      if (!newProduct || !newProduct.availableForSale) continue;
      newProducts = [...newProducts, newProduct];
    }

    setShopifyProducts(newProducts);
  };

  useEffect(() => {
    updateProducts(upsellProductIds);
  }, [upsellProductIds]);

  return { shopifyProducts };
};
