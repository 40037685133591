// DutiesContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import Cookies from "js-cookie";
import { useCart } from "./CartProvider"; // adjust path as needed

const COOKIE_NAME = "dutiesAccepted";
const DUTY_COUNTRY_EXCLUDE = ["NZ", "AU"];

interface DutiesContextType {
  dutiesAccepted: boolean;
  setDutiesAccepted: (accepted: boolean) => void;
  dutiesReminder: boolean;
  setDutiesReminder: (required: boolean) => void;
  dutiesRequired: boolean;
  checkoutAvailable: boolean;
}

const DutiesContext = createContext<DutiesContextType | undefined>(undefined);

export const DutiesProvider: React.FC<DutiesProviderProps> = ({ children }) => {
  const { country } = useCart();
  const [dutiesReminder, setDutiesReminder] = useState(false);
  const [dutiesAccepted, setDutiesAcceptedFlag] = useState(false);

  useEffect(() => {
    setDutiesAcceptedFlag(Cookies.get(COOKIE_NAME) == "accepted");
  }, []);

  const setDutiesAccepted = (accepted: boolean) => {
    setDutiesAcceptedFlag(accepted);

    if (accepted) {
      Cookies.set(COOKIE_NAME, "accepted", { expires: 365 });
      setDutiesReminder(false);
    } else {
      Cookies.remove(COOKIE_NAME);
      setDutiesReminder(true);
    }
  };

  const dutiesRequired = useMemo(
    () => Boolean(country && !DUTY_COUNTRY_EXCLUDE.includes(country.isoCode)),
    [country],
  );

  const checkoutAvailable = useMemo(() => {
    return !dutiesRequired || !!dutiesAccepted;
  }, [dutiesRequired, dutiesAccepted]);

  return (
    <DutiesContext.Provider
      value={{
        dutiesAccepted,
        setDutiesAccepted,
        dutiesReminder,
        setDutiesReminder,
        dutiesRequired,
        checkoutAvailable,
      }}
    >
      {children}
    </DutiesContext.Provider>
  );
};

export const useDuties = () => {
  const context = useContext(DutiesContext);
  if (!context) {
    throw new Error("useDutiesContext must be used within a DutiesProvider");
  }
  return context;
};

interface DutiesProviderProps {
  children: React.ReactNode;
}

export default DutiesProvider;
