import styled from "styled-components";
import { useRouter } from "next/router";
import { MobileBreakpoint } from "../../styles/functions";
import { TypeBodyM } from "../../styles/mixins";
import ButtonPrimary from "../button/ButtonPrimary";
import { useDuties } from "src/contexts/DutiesProvider";
import { pixelCheckout } from "src/hooks/usePixel";
import { useCart } from "src/contexts/CartProvider";

const CartCheckout: React.FC = ({ ...props }) => {
  const { cart } = useCart();
  const router = useRouter();
  const { checkoutAvailable, dutiesReminder } = useDuties();

  const onClick = () => {
    try {
      pixelCheckout(cart, { cart });
      router.push(cart?.checkoutUrl);
    } catch (e: any) {
      console.log("Checkout", e);
      window.location = cart?.checkoutUrl;
    }
  };

  return (
    <Button onClick={onClick} disabled={!checkoutAvailable} {...props}>
      Proceed to Checkout
    </Button>
  );
};

const Button = styled(ButtonPrimary)`
  width: 100%;

  ${MobileBreakpoint} {
    margin-left: -16px;
    margin-bottom: -8px;
    width: calc(100% + 32px);
    padding: 12px 16px;
    justify-content: flex-start;
    ${TypeBodyM};
  }
`;

export default CartCheckout;
